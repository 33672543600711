/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */
export enum AppHeaderVisibility {
  Logo = 0,
  Menu = 1,
  Header = 2,
  UserInfo = 4,
  SignIn = 8,
  Default = 7,
}
