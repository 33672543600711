/*
 * @author BSG <dev@bsgroup.eu>
 * @copyright Better Software Group S.A.
 * @version: 1.0
 */

import React from "react";
import dynamic from "next/dynamic";
import cx from "classnames";

import { AppHeaderVisibility } from "enums/AppHeaderVisibility";

const AppHeader = dynamic(() => import("components/AppHeader/AppHeader"));

const AppFooterDesktop = dynamic(
  () => import("components/AppFooter/AppFooterDesktop")
);

import layoutStyles from "../Layout/Layout.module.scss";
import styles from "./LoginLayout.module.scss";
import footerDesktopStyles from "components/AppFooter/AppFooter.module.scss";
import { IConfigurationModel } from "@nf/common";

interface LayoutProps {
  children: React.ReactNode;
  config: IConfigurationModel;
}

const LoginLayout = ({ children, config }: LayoutProps) => {
  return (
    <div className={cx(layoutStyles.container, styles.layout)}>
      <AppHeader
        configuration={config}
        visibility={AppHeaderVisibility?.Logo}
      />
      <section>{children}</section>
      <div className={footerDesktopStyles.footerDesktop}>
        <AppFooterDesktop config={config} />
      </div>
    </div>
  );
};

export default LoginLayout;
